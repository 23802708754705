<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Props:
		startDate "2020-12-06"
		endDate   "2020-12-12"
		format    "jFY" > 6. Dezember 2020
				  "jMY" > 6. Dez 2020
				  "jM"  > 6. Dez

	Beispiel Code:
		<OvDate
			:startDate="startDate"
			:endDate="startDate"
			:format="jFY">
			<slot name="append">123</slot>
		</OvDate>

	2020-11-23	init

-->

<template>
	<div class="OvDate" :class="elmClasses" v-if="getDateString()">
		<div class="OvDate__inner" v-if="startDate">
			<span v-html="getDateString()"></span>
			<slot name="append"></slot>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'OvDate',
		components: {},
		mixins: [],
		props: {
			startDate: {
				type: [Number, String, Boolean],
				default: false,
			},
			endDate: {
				type: [Number, String, Boolean],
				default: false,
			},
			format: {
				type: [String, Boolean],
				default: 'jFY', // jFY, jF, jMY, dM, dm
			},
		},
		data() {
			return {
				monthNames__de : [
					'Januar',
					'Februar',
					'März',
					'April',
					'Mai',
					'Juni',
					'Juli',
					'August',
					'September',
					'Oktober',
					'November',
					'Dezember',
				],
				monthNames__en : [
					'January',
					'February',
					'March',
					'April',
					'May',
					'June',
					'July',
					'August',
					'September',
					'October',
					'November',
					'December',
				]
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			transformDate( ymd ){
				let returnString = ''

				if( ymd ){
					const parts   = ymd.split('-')
					const year    = parts[0]
					const month   = parts[1]
					let day       = parts[2]
					let dayJoiner = this.format === 'dm' ? ' ' : '&nbsp;'
					//joiner = ''

					if( this.format == 'jFY' || this.format == 'jF' ) day = this._.trimStart(day, 0)

					returnString = day + '.' + dayJoiner + this.getMonthName( month ) + ' ' + this.getYear( year )
					returnString = this._.trim( returnString )

					if( this.format === 'dm' ) returnString = returnString + '.'
				}


				//return this._.trimStart( returnString, '')
				return returnString
			},
			getMonthName( monthNumber ){
				const currentLang = this.$i18n.locale
				const monthNames  = this._.get( this.app, 'monthNames.' + currentLang, [])
				let monthName     = monthNames[monthNumber-1]

				if( this.format == 'jMY' || this.format == 'dM' ) monthName = monthName.substring(0, 3)
				if( this.format == 'dm' ) monthName = monthNumber

				return monthName
			},
			getYear( year ){
				return ( this.format === 'jFY' || this.format == 'jMY' ) ? year : ''
			},
			getDateString(){
				const parts = [
					this.transformDate( this.startDate ),
					this.transformDate( this.endDate )
				]
				const joiner = this.$route.params.lang === 'de' ? ' bis<br />' : ' to<br />'
				return this.endDate ? parts.join(joiner) : parts[0]
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvDate {
		//background-color: fade( green, 20 );

		display: inline-block;

		&__inner {
			display: inline-block;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
