<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvMainFooter></OvMainFooter>

	2020-11-23	init

-->

<template>
	<div class="OvMainFooter contentModule background background--white" :class="elmClasses" v-resize="onResize">
		<div class="OvMainFooter__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app">
			<div class="OvMainFooter__sizeDetector" ref="sizeDetectorElm"></div>
			<div class="OvMainFooter__left" ref="leftElm">
				<div class="OvMainFooter__metaInfos font font--sizeMini">
					<div class="OvMainFooter__metaInfosSocialLinks">
						<a class="OvMainFooter__metaInfosSocialLink OvMainFooter__metaInfosSocialLink--in" :href="_.get( app, 'acfOptions.socialMedia.instagram')" target="_blank">Instagram</a>
						<a class="OvMainFooter__metaInfosSocialLink OvMainFooter__metaInfosSocialLink--tw" :href="_.get( app, 'acfOptions.socialMedia.twitter')" target="_blank">Twitter</a>
						<a class="OvMainFooter__metaInfosSocialLink OvMainFooter__metaInfosSocialLink--yt" :href="_.get( app, 'acfOptions.socialMedia.youtube')" target="_blank">YouTube</a>
						<a class="OvMainFooter__metaInfosSocialLink OvMainFooter__metaInfosSocialLink--fb" :href="_.get( app, 'acfOptions.socialMedia.facebook')" target="_blank">Facebook</a>
					</div>
					<div class="OvMainFooter__metaInfosText richText color color--grey" v-html="infoText"></div>
				</div>
				<!--
				Der Newsletter-Link wird jetzt direkt über das WYSIWIG-Feld im Backend gelöst.

				<div class="OvMainFooter__newsletterSubscription font font--sizeMini">
					<a class="mailjet-openWidget" data-token="40281af3a6e1a37c2ad0649f04abe563">Newsletter abonnieren!</a>
				</div>
				-->
				<!--
				<OvLabel2
					class="OvMainFooter__newsletterSubscriptionBtn font--sizeSmall"
					:class="{'font--sizeMedium' : ['md', 'sm', 'xs'].includes($mq)}"
					:text="$t('button.newsletterSubscription')"
					bgColor="white"
					textColor="currentColor"
					:forceShadow="true"
					data-token="40281af3a6e1a37c2ad0649f04abe563"
					@click.native="EventBus.$emit('mailjet-openWidget', $event)"
				></OvLabel2>
				 -->
			</div>
			<div class="OvMainFooter__right" ref="rightElm">
				<OvMainMenu class="OvMainHeader__mainMenu" :showGroupLinks="showGroupLinks"></OvMainMenu>
				<!--
				<br />
				<input type="checkbox" v-model="showGroupLinks" />
				-->
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import OvMainMenu from '@/components/OvMainMenu.vue'
	import OvLabel2 from '@/components/OvLabel2.vue'

	export default {
		name: 'OvMainFooter',
		components: {
			OvMainMenu,
			OvLabel2,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				showGroupLinks : true,
				hasRowLayout : null,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.hasRowLayout ) classes.push( this.$options.name + '--hasRowLayout')

				return classes
			},
			infoText(){
				const currentLang = this.$i18n.locale
				const infoText    = this._.get( this.app, 'acfOptions.footerText__' + currentLang, '')

				return infoText
			},
		},
		methods: {
			onResize({ width, height, doLog = false }) {
      			// see https://github.com/wangweiwei/vue-resize-observer

				//this.width  = width
      			//this.height = height

				const sizeDetectorElm = this.$refs.sizeDetectorElm.offsetWidth
				const leftElmWidth    = this.$refs.leftElm.offsetWidth
				const rightElmWidth   = this.$refs.rightElm.offsetWidth
				const hasRowLayout    = (leftElmWidth + rightElmWidth) > sizeDetectorElm ? true : false

				if( doLog ){
					console.group( this.$options.name, '• onResize()')
					console.log('sizeDetectorElm:', sizeDetectorElm)
					console.log('leftElmWidth:', leftElmWidth, 'rightElmWidth:', rightElmWidth)
					console.log('hasRowLayout:', hasRowLayout)
					console.groupEnd()
				}

				this.hasRowLayout = hasRowLayout
    		},
		},
		created(){},
		mounted(){
			this.EventBus = EventBus
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvMainFooter {	// debug
		[showBorders4] &__inner > * {
			outline: 1px solid red;
		}
		[showBorders4] &__inner > * * {
			outline: 1px solid orange;
		}
		[showBorders4] &__left {
			background-color: fade( green, 30 );
		}
		[showBorders4] &__right {
			background-color: fade( cyan, 30 );
		}
	}
	.OvMainFooter { // layout
		&__inner {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		&__left {
			//background-color: fade( green, 20 );
			width: 10em;
			flex-shrink: 0;
			//overflow: hidden;
		}
		&__right {
			//background-color: fade( red, 20 );
			flex-shrink: 0;
			justify-content: flex-end;
		}
		&__sizeDetector {
			//background-color: fade( blue, 50 );
			height: 1px; width: 100%;
			margin-bottom: -1px;
		}

		&__left {}
		&__right {}

		@media @mq[md] {
			&__left { width: 100%; }
			&__right { display: none; }
		}

		&--hasRowLayout {
			//background-color: yellow;

			//.OvMainFooter__inner { justify-content: flex-end; }
			.OvMainFooter__right { height: 1px; overflow: hidden; }
			//.OvMainFooter__right { order: 1}
			/*
			.OvMainFooter__metaInfosText { visibility: hidden; }
			.OvMainFooter__metaInfosSocialLinks { flex-direction: rows; }
			.OvMainFooter__metaInfosSocialLink:first-child { margin: 0; }
			.OvMainFooter__metaInfosSocialLink { width: 100%; }
			*/
		}
	}
	.OvMainFooter { // styling
		border-top: 1px solid var(--color-primary-backgroundLine);
		overflow: hidden; // this prevents horizontal scrollbars in narrow cases

		&__inner {
			padding-top: 0.75em;
			padding-bottom: 0.75em;
		}

		&__left {
			& > *:last-child {
				margin-bottom: 3em;
			}
		}

		&__metaInfos {

			&Text {
				padding-bottom: 2em;
			}
			&SocialLinks {
				padding-top: 0.5em;
				padding-bottom: 1.25em;
			}
			&SocialLink {
				display: inline-block;
				height: 2em; width: 4em;
				overflow: hidden;
				color: transparent;
				user-select: none;

				&:first-child { margin-left: -0.95em; }

				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				opacity: 0.5; &:hover { opacity: 1; }

				&--in { background-image: url(../assets/imgs/icon--instagram.svg); }
				&--tw { background-image: url(../assets/imgs/icon--twitter.svg); }
				&--yt { background-image: url(../assets/imgs/icon--youtube.svg); }
				&--fb { background-image: url(../assets/imgs/icon--facebook.svg); }
			}
		}
		&__newsletterSubscription {
			a {
				color: var(--color-primary-baseLink);
				&:hover { text-decoration: underline; }
			}
		}
		/*
		&__newsletterSubscriptionBtn {
			cursor: pointer;
		}
		*/
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
