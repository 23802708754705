<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Props:
		startTime "09:00"
		endTime   "12:30"

	Beispiel Code:
		<OvTime
			:startTime="'09:00'"
			:endTime="'12:30'"
		></OvTime>

	2020-11-23	init

-->

<template>
	<div class="OvTime" :class="elmClasses" v-if="startTime">
		<div class="OvTime__inner" v-html="getTimeString()"></div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'OvTime',
		components: {},
		mixins: [],
		props: {
			startTime: {
				type: [String, Boolean],
				default: false,
			},
			endTime: {
				type: [String, Boolean],
				default: false,
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			transformTime( Hi ){
				const parts   = Hi ? Hi.split(':') : []
				const hours   = this._.trimStart( parts[0], '' )
				const minutes = this._.replace( parts[1], '', '' )

				let    output = (hours && minutes) ? hours + ':' + minutes : hours

				return output
			},
			getTimeString(){
				const currentLang = this.$i18n.locale
				const parts = [
					this.transformTime( this.startTime ),
					this.transformTime( this.endTime )
				]
				let timeString = this.endTime ? parts.join(' — ') : parts[0] //+ ' Uhr'
				if( currentLang == 'en' ) timeString = timeString.replace(' Uhr', '')

				return timeString
			},
		},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvTime {
		//background-color: fade( blue, 20 );

		display: inline-block;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
