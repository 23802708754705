<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvMainHeadline></OvMainHeadline>

	2019-06-08	init

-->

<template>
	<div class="OvMainHeadline contentModule" :class="elmClasses" v-if="post || text || isSearchLayout">
		<div class="OvMainHeadline__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app">
			<!-- breadcrumb -->
			<div class="OvMainHeadline__breadcrumb font font--bold font--sizeMini miniTitleWithLine">
				<span class="OvMainHeadline__breadcrumbLinks">
					<template v-for="(breadcrumbItem, index) in breadcrumbItems">
						<router-link
							v-if="breadcrumbItem.to"
							class="OvMainHeadline__breadcrumbLink"
							:to="breadcrumbItem.to"
							:key="index"
						>{{breadcrumbItem.title}}</router-link>
						<span v-else :key="index">
							{{breadcrumbItem.title}}
						</span>
						<!--
						<span class="OvMainHeadline__breadcrumbLink" :key="index">{{breadcrumbItem}}</span>
						-->
						<span class="OvMainHeadline__breadcrumbSeperator" :key="'s'+index">/</span>
					</template>
				</span>
				<!--
				<div class="XXXOvMainHeadline__breadcrumbLine"></div>
				-->
			</div>
			<!-- title with optional label + event date -->
			<h1 class="OvMainHeadline__titleWrapper font--sizeHuge lineCrop hyphenate" v-if="!isSearchLayout">
				<template v-if="['xl', 'lg', 'dt'].includes($mq)">
					<OvLabel2 class="font font--sizeDefault" :text="labelText" :verticalAlign="'middle'"></OvLabel2>
					<div class="OvMainHeadline__eventDateTime inlineBlock font font--sizeDefault" v-if="post.type == 'veranstaltungen_post'">
						<OvDate class="OvMainHeadline__eventDate font font--bold" :startDate="eventStartDate" format="jFY"></OvDate><br/>
						<OvTime class="OvMainHeadline__eventTime" :startTime="eventStartTime" :endTime="eventEndTime"></OvTime><br/>
					</div>
					<span class="font font--bold" v-html="titleText"></span><br/>
				</template>
				<template v-if="['md', 'sm', 'xs'].includes($mq)">
					<div class="OvMainHeadline__eventDateTime inlineBlock font font--sizeHuge" v-if="post.type == 'veranstaltungen_post'">
						<OvDate class="OvMainHeadline__eventDate font font--bold" :startDate="eventStartDate" format="jFY"></OvDate><br/>
						<OvTime class="OvMainHeadline__eventTime" :startTime="eventStartTime" :endTime="eventEndTime"></OvTime><br/>
					</div>
					<OvLabel2 class="font font--sizeDefault" :text="labelText" :verticalAlign="'middle'"></OvLabel2>
					<span class="font font--bold" v-html="titleText"></span><br/>
				</template>
			</h1>
			<!-- searchbar if isSearchLayout -->
			<div class="OvMainHeadline__search font--sizeBig lineCrop hyphenate" v-if="isSearchLayout">
				<input
					class="OvMainHeadline__searchInput"
					v-model="searchString"
					v-on:keyup.enter="changeSearchTerm"
					:placeholder="$t('title.search') + ' ...'"
					ref="searchInput"
					type="text"
				/>
				<a class="OvMainHeadline__searchIcon" role="button" @click="changeSearchTerm">{{$t('title.search')}}</a>
			</div>
			<!--
			<pre name="searchString">{{searchString}}</pre>
			-->
			<!-- exhibition dates -->
			<OvDate v-if="post.type == 'ausstellungen_post'"
				class="OvMainHeadline__exhibitionDate font font--bold font--sizeHuge"
				:startDate="exhibitionStartDate"
				:endDate="exhibitionEndDate">
				<template slot="append" v-if="_.get( post, 'acf.isExtended', false )">
					<span class="exhibitionExtendedLabel">
						<span
							class="exhibitionExtendedLabel__text font"
							:class="['xl', 'lg', 'dt'].includes($mq) ? 'font--sizeSmall' : 'font--sizeMini'"
							v-html="$t('text.extendedExhibition')"
						></span>
					</span>
				</template>
			</OvDate>
			<!-- Blogpost Metas -->
			<div class="OvMainHeadline__blogPostMetas" v-if="post.type == 'post'">
				<div>
					<br/>
					<OvLabel2
						:text="$t('button.gotoBlog')"
						bgColor="white"
						textColor="currentColor"
						:showArrowBefore="true"
						:showArrowAfter="false"
						:routerLink="{ name: 'OvBlogPostsView' }"
					></OvLabel2>
				</div>
				<div>
					<br/>
					<div class="font font--sizeSmall">
						<template v-if="_.get( post, 'acf.sourceInfo')">
							{{$t('text.source')}}: {{_.get( post, 'acf.sourceInfo')}}<br/>
						</template>
						<template v-if="_.get( post, 'acf.author')">
							{{$t('text.author')}}: {{_.get( post, 'acf.author')}}<br/>
						</template>
						{{$t('text.postedAt')}}: <OvDate :startDate="getTimestamp( post.date )"></OvDate>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import OvLabel2 from '@/components/OvLabel2.vue'
	import OvDate from '@/components/OvDate.vue'
	import OvTime from '@/components/OvTime.vue'

	export default {
		name: 'OvMainHeadline',
		components: {
			OvLabel2,
			OvDate,
			OvTime,
		},
		mixins: [],
		props: {
			post: {
				type: [Object, Boolean],
				default: false,
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
			isSearchLayout: {
				type: [Boolean],
				default: false,
			},
		},
		data() {
			return {
				searchString : '',
			}
		},
		watch: {
			post: {
				handler: function( to, from ) {
				},
				deep: true,
				immediate: true,
			},
			'app.searchString': {
				handler: function( to, from ) {
					this.searchString = to
				},
				deep: false,
				immediate: true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			breadcrumbItems(){
				const items = [
					{ title : this.$t('breadcrumb.home'), to : { name: 'OvHomeView' } },
				]

				if( this.post.type == 'veranstaltungen_post' ){
					items.push({ title : this.$t('breadcrumb.events'), to : { name: 'OvEventsView' } })
				}
				if( this.post.type == 'post' ){
					items.push({ title : this.$t('breadcrumb.blog'), to : { name: 'OvBlogPostsView' } })
				}
				if( this.post.type == 'ausstellungen_post' ){
					const isArchived = this.app.isArchivedExhibition( this.post )
					const isRunning  = this.app.isRunningExhibition( this.post )
					const isUpcoming = this.app.isUpcomingExhibition( this.post )

					if( isArchived ) items.push({ title : this.$t('breadcrumb.archivedExhibitions'), to : { name: 'OvPastExhibitionsView' } })
					if( isUpcoming ) items.push({ title : this.$t('breadcrumb.upcomingExhibitions'), to : { name: 'OvFutureExhibitionsView' } })
					if( isRunning )  items.push({ title : this.$t('breadcrumb.currentExhibitions'),  to : { name: 'OvCurrentExhibitionsView' } })
				}

				return items
			},
			labelText(){
				return this.app.getPostLabelText( this.post )
			},
			titleText(){
				return this.app.getPostTitle( this.post ) + this.text
			},
			exhibitionStartDate(){
				return this._.get( this.post, 'acf.startDate')
			},
			exhibitionEndDate(){
				return this._.get( this.post, 'acf.endDate')
			},
			eventStartDate(){
				return this._.get( this.post, 'acf.startDate')
			},
			eventStartTime(){
				return this._.get( this.post, 'acf.startTime')
			},
			eventEndTime(){
				return this._.get( this.post, 'acf.endTime')
			},
		},
		methods: {
			getTimestamp( dateStr ){
				const ts = dateStr ? Date.parse(dateStr) : null
				const yyyymmdd = ts ? this.app.dateToYYYYMMDD( ts, '-' ) : null

				return yyyymmdd
			},
			changeSearchTerm(){
				const newTerm = this.searchString
				EventBus.$emit('changeSearchTerm', newTerm)

				//console.log('changeSearchTerm:', newTerm)
			},
		},
		created(){},
		mounted(){
			// focus search input on load
			if( this.$refs.searchInput ) this.$refs.searchInput.focus()

			//console.log( this.$options.name + ' • mounted', )
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvMainHeadline {	// debug
		&__text {
			//background-color: fade( red, 75);
		}
		&__exhibitionDate {
			//background-color: fade( orange, 75);
		}
	}
	.OvMainHeadline {	// layout
		&__breadcrumb {}
		&__blogPostMetas {
			//background-color: fade( red, 20 );

			.grid;
			.grid--setAreas;
			.grid--colGap;

			@media @mq[dt] { .grid--cols-12; }
			@media @mq[md] { .grid--cols-8; }

			grid-template-areas: "a a b b b b b b b b b b";

			@media @mq[md] {
				grid-template-areas: "b b b b b b b b"
								     "a a a a a a a a";
			}
		}
	}
	.OvMainHeadline {	// styling
		&__breadcrumb {
			padding-bottom: 2em;

			&Link + &Link {
				margin-left: 0.5em;
			}

			&Links {
				padding-right: 0.1em;
			}
			&Link {
				color: inherit;
			}
			&Seperator {
				//background-color: fade( red, 20 );
				display: inline-block;
				padding: 0 0.35em;
				opacity: 0.75;

				//&:last-child { display: none; }
			}

			@media @mq[md] {
				padding-bottom: 1em;
			}
		}

		&__titleWrapper {}
		&__titleWrapper .OvLabel2 {
			margin-right: 0.75em;
			transform: translateY(-52%);
			transform-origin: top left;

			@media @mq[md] {
				transform: translateY(-16%) scale(0.85);
				margin-right: 0.1em;
			}
		}

		&__titleWrapper,
		&__exhibitionDate {
			max-width: 16em;
		}
		&__eventDateTime {
			//outline: 1px solid red;
			line-height: 1.1em;
			padding-right: 0.75em;

			@media @mq[md] {
				display: flex;
				flex-direction: column;
				//order: 1; // below the event title
				//display: none;
				//justify-content: space-between;
				//padding-left: 8%;
				padding-right: 0;
				padding-bottom: 0.5em;
				line-height: 1.25em;

				br { display: none; }
			}
		}
		&__exhibitionDate {
			padding-top: 0.555em;
		}

		// searchbar
		&__search {
			@iconWidth : 2em;

			position: relative;
			padding-top: 0em;
			border-bottom: 1px solid var(--color-primary-backgroundLine);

			&Input {
				padding-left: 0.35em;
				width: calc( 100% - @iconWidth );
				height: 1.75em;

				transition: all 0.1s ease;
				background-color: fade( white, 75 );
				border: none;
				outline: none;
				font: inherit;
			}
			&Input:focus {
				background-color: fade( white, 100 );
			}
			&Input::placeholder {
				color: fade( black, 35 );
			}

			&Icon {
				position: absolute;
				top: 0; right: 0; bottom: 0;
				width: @iconWidth;
				border: 0.5em solid transparent;

				//background-color: fade( red, 20 );
				background-color: fade( white, 75 );
				background-image: url('./../assets/imgs/search-icon.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				overflow: hidden;
				color: transparent;
				cursor: pointer;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
