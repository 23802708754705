<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvLabel2></OvLabel2>

	2020-11-23	init

-->

<template>
	<div class="OvLabel2" :class="elmClasses" :style="elmStyles" v-if="showElm">
		<template v-if="routerLink">
			<router-link class="OvLabel2__inner" :to="routerLink" :class="elmInnerClasses" :style="elmInnerStyles">
				<div class="OvLabel2__arrowBefore" v-if="showArrowBefore"></div>
				<span class="OvLabel2__sizer"></span>
				<div class="OvLabel2__text font font--bold" v-html="useText"></div>
				<div class="OvLabel2__arrowAfter" v-if="showArrowAfter">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 21">
					  <path id="Pfad_64" data-name="Pfad 64" d="M2.55-11.433H21.3L15-17.862l2.34-2.418L27.63-9.78,17.34.72,15-1.7l6.3-6.429H2.55Z" transform="translate(-2.55 20.28)" fill="rgba(0,0,0,0.97)"/>
					</svg>
				</div>
			</router-link>
		</template>
		<template v-else>
			<div class="OvLabel2__inner" :class="elmInnerClasses" :style="elmInnerStyles">
				<div class="OvLabel2__arrowBefore" v-if="showArrowBefore"></div>
				<span class="OvLabel2__sizer"></span>
				<div class="OvLabel2__text font font--bold" v-html="useText"></div>
				<div class="OvLabel2__arrowAfter" v-if="showArrowAfter"></div>
			</div>
		</template>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'OvLabel2',
		components: {},
		mixins: [],
		props: {
			text: {
				type: [String, Boolean],
				default: '',
			},
			bgColor: {
				type: [String, Boolean],
				default: 'currentColor',
			},
			textColor: {
				type: [String, Boolean],
				default: 'var(--color-primary-background)',
			},
			showArrowBefore: {
				type: [Boolean],
				default: false,
			},
			showArrowAfter: {
				type: [Boolean],
				default: false,
			},
			forceShadow: {
				type: [Boolean],
				default: false,
			},
			routerLink: {
				type: [Object, String, Boolean],
				default: false,
			},
			verticalAlign: {
				type: [String, Boolean],
				default: 'bottom',
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('OvLabel2--type' + this._.upperFirst(this.type) )

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.routerLink || this.forceShadow ) classes.push('hover hover--OvLabel2')
				if( this.routerLink || this.forceShadow ) classes.push('shadow shadow--OvLabel2')
				if( this.showArrowBefore && this.text == '' ) classes.push('shadow--OvLabel2-circleForm')
				if( this.showArrowAfter && this.text == '' ) classes.push('shadow--OvLabel2-circleForm')

				return classes
			},
			elmStyles(){
				let styles = {}

				styles.backgroundColor = this.bgColor
				//styles.color = this.textColor
				styles.verticalAlign = this.verticalAlign

				return styles
			},
			elmInnerStyles(){
				let styles = {}

				//styles.backgroundColor = this.bgColor
				styles.color = this.textColor
				//styles.verticalAlign = this.verticalAlign

				return styles
			},
			showElm(){
				const hasText = this.text && this.text != 'none' ? true : false
				return hasText || this.showArrowBefore || this.showArrowAfter ? true : false
			},
			useText(){ // text filter option
				let inputText  = this.text
				let outputText = inputText

				if( 'Labor' == inputText ) outputText = 'LABOR'
				if( 'Schleuse' == inputText ) outputText = 'SCHLEUSE'
				if( 'Schleuse + Labor' == inputText ) outputText = 'SCHLEUSE + LABOR'

				return outputText
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvLabel2 {
		//background-color: fade( green, 50 );

		position: relative;
		display: inline-flex;
		min-width: 1em;
		min-height: 1em;
		border-radius: 1em;
		flex-grow: 0;
		//overflow: hidden;

		&__inner {
			display: flex;
			border-radius: inherit;
			padding: 0 0.2em;
			font-size: 0.7em;
			//line-height: 1.35em;
			flex-grow: 1;
			color: inherit;
			align-items: center;
		}
		&__sizer {
			&::before {
				content: "M";
				display: block;
				width: 0;
				overflow: hidden;
			}
		}
		&__text {
			padding: 0 0.5em;

			white-space: nowrap;
			letter-spacing: 0.02em;
			//text-transform: uppercase;
			color: inherit;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			&:empty { display: none; }
		}
		&__arrowBefore {
			width: 1em; height: 1em;
			margin-left: 0.15em;
			margin-right: 0.15em;
			flex-shrink: 0;
			transform: scale(1.1) rotate(180deg);

			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(../assets/imgs/icon--arrow.svg);
		}
		&__arrowAfter {
			width: 1em; height: 1em;
			margin-left: 0.15em;
			margin-right: 0.15em;
			flex-shrink: 0;
			transform: scale(1.1);

			/*
			object-fit: contain;
			object-position: center;
			display: flex;
			*/

			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(../assets/imgs/icon--arrow.svg);
			//background-image: linear-gradient(red,green), url(../assets/imgs/icon--arrow.svg);
    		//background-blend-mode: multiply;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
